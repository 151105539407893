body {
  overflow: hidden;
}

.card .card-body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.page-header {
  background-image: url("./../img/back.png");
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
}

.login-wrapper {
  background-image: url("./../img/back.png");
  padding: 0;
  position: relative;
  background-size: cover;
  background-position: 50%;
}

.bg-gradient-dark {
  background-image: linear-gradient(195deg, #ab2e6e 0%, #0070b4 100%);
}

.send-proposal {
  background-image: linear-gradient(195deg, #ec407a 0%, #d81b60 100%);
  color: #fff;
}

.mask {
  position: absolute;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.opacity-6 {
  opacity: 0.4 !important;
}

.navbar {
  background-color: #f0f2f5;
}

.border-radius-xl {
  border-radius: 0.75rem;
}

.z-index-3 {
  z-index: 3 !important;
}

.card1 {
  margin-top: 160px;
  margin-bottom: 50px;
  border-radius: 16px;
}

.card2 {
  margin-top: 150px;
  border: none;
}

.border-radius-lg {
  border-radius: 0.5rem;
}

.bg-gradient-primary {
  background-image: linear-gradient(195deg, #ec407a 0%, #d81b60 100%);
}

.bg-gradient-primary-login {
  background-image: linear-gradient(195deg, #ec407a 0%, #d81b60 100%);
  font-weight: bold;
}

.bg-gradient-primary h4 {
  font-weight: 700;
}

.shadow-primary {
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(233 30 99 / 40%) !important;
}

.card-header:first-child {
  border-radius: 0.75rem 0.75rem 0 0;
}

.z-index-2 {
  z-index: 2 !important;
}

.position-relative {
  position: relative !important;
  top: -25px;
}

.card-header {
  /*padding: 0.5rem 1rem;*/
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.form-switch .form-check-input:checked {
  border-color: #42424a;
  background-color: #42424a;
}

.text-primary {
  color: #e91e63 !important;
  text-decoration-line: none;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.btn1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

.btn2 {
  color: #fff;
  font-size: 14px;
  border: solid 2px #000;
  height: 43px;
  border-radius: 8px;
}

.btn.bg-gradient-primary:hover {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 14px 26px -12px rgb(233 30 99 / 40%),
    0 4px 23px 0 rgb(233 30 99 / 15%), 0 8px 10px -5px rgb(233 30 99 / 20%);
}
.box-padding {
  padding: 5px !important;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.5;
  font-weight: 300;
}
.custom-icon {
  margin-right: 1.7px !important;
}

.footer1 {
  text-decoration-line: none;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.Card-p {
  line-height: 1.625;
  font-weight: 300;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked {
  background-color: #e91e63;
  border-color: #e91e63;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"] {
  border: 1px solid #d1d7e1;
  margin-top: 0.25rem;
  margin-right: 5px;
  position: relative;
  color: #fff;
}

.form-check:not(.form-switch) .form-check-input {
  float: initial !important;
  margin-left: auto !important;
}

.card-footer {
  border-top: none;
}

.form-check-label {
  font-size: 0.875rem;
  font-weight: 400;
  cursor: pointer;
}

.form-check-label a {
  text-decoration-line: none;
}

.navbar .navbar-brand {
  color: #344767;
  font-size: 1rem;
  font-weight: 700 !important;
  /* padding-left: 20px; */
  padding: 4px 10px;
  margin: 0;
  border-bottom: solid 1px lightgray;
}

.col6 {
  z-index: -1;
  margin-top: 40px;
  margin-left: 20px;
}

/* New */
.sub-menu {
  align-items: center;
  display: flex;
}

.a-link {
  cursor: pointer;
}

.center-box {
  justify-content: center !important;
}

.full-map .esri-ui-top-left {
  top: 60px;
}

.full-layout-content {
  top: 60px;
}

.table tr {
  vertical-align: middle;
}

.user-mgt-detail-init {
  /* height: 70vh !important; */
}

.request-list-init,
.request-detail-init,
.user-mgt-init,
.user-mgt-detail-init,
.proposal-list-init,
.proposal-detail-init,
.new-request-init,
.analysis-detail-init,
.new-proposal-init,
.mis-filter-popup,
.analysis-detail-init {
  opacity: 1 !important;
}

.new-request-popup {
  height: 90vh !important;
  overflow: auto !important;
}

.new-proposal-popup {
  height: 90vh !important;
  overflow: auto !important;
}

.new-aggerator-popup {
  height: 90vh !important;
  overflow: auto !important;
}

.new-request-btn {
  right: -65px !important;
}

.gis-corner-btn {
  right: 0;
  left: -37px;
  position: relative !important;
}

.land-summary-btn {
  top: 350px !important;
  overflow: auto !important;
}

.land-details-popup {
  height: 90vh;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none !important;
}

.esri-ui-top-left.esri-ui-corner {
  display: none;
}

/* .panel-title {
  position: sticky;
  top: 0;
} */

.gis-map {
  margin-top: -32px !important;
}

.pending-request {
  background: #e7e7e7;
  color: #525252 !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.in-progress-request {
  background: #ffa500;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.complete-request {
  background: #00ff00;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.proposal-assigned {
  background: #0000ff;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
  text-wrap: nowrap;
}

.hold-request {
  background: #ff0000;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.acquisition-request {
  background: #00ff00;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.completed-request {
  background: #00c5e1;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.acq-staus-chart {
  border: solid 3px #fff;
  border-radius: 15px;
  margin: 15px;
}

.dashboard-widget {
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.toogle-map {
  position: absolute;
  top: 300px;
  left: -54px;
  color: #fff;
  transform: rotate(90deg);
  border: none;
}

.mis-filter-popup {
  border-radius: 0 25px 25px 0;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  height: 85vh !important;
  overflow: auto !important;
  width: 450px;
  transition: width 600ms ease-out, height 600ms ease-out;
}

.toaster-header {
  background-image: linear-gradient(160deg, #7d8dd9 14%, #ef1463 100%);
  color: #fff;
}

.notifier-name {
  margin-left: 258px;
  margin-top: -19px;
}

.notification-message-box {
  border-radius: "5px";
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mark-as-read-button {
  color: blue;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 114px;
  padding: 5px;
}

.notification-header {
  position: absolute;
  top: 0px;
  width: 398px;
  background-image: linear-gradient(160deg, #7d8dd9 14%, #ef1463 100%);
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.notification-body {
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 60px;
}

.toaster-body {
  background-color: #fff;
}

.table-head {
  position: sticky;
  top: 0;
}

.selected-row {
  background-color: rgb(55, 93, 177);
}

.reject-status {
  background: #ff0000;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.accept-status {
  background: #00ff00;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

.forward-status {
  background: #0000ff;
  color: #fff !important;
  padding: 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
}

/* Loadin skelton */
.loader-skelton {
  position: absolute;
  top: 120px;
  z-index: 1001;
  left: 20px;
}

.tile {
  width: 90%;
  height: 30px;
  background-color: lightgray;
  display: block;
  margin-bottom: 5px;
}

/* End of loading skelton */

/* .list-table .td {
  width: 200px;
} */

.view-file {
  cursor: pointer;
  padding-left: 5px;
}

.file-label {
  cursor: pointer;
  padding-left: 5px;
  text-decoration: underline;
}

.site-filter {
  position: absolute !important;
  left: 40px;
  top: 80px;
  width: 500px;
  display: flex;
}

.base-map-container {
  position: absolute !important;
  right: 380px;
  top: 67px;
  width: 500px;
  display: flex;
  max-height: 380px;
  display: none;
}

.measurement-area {
  position: absolute !important;
  left: 775px;
  top: 15px;
  display: flex;
  z-index: 100;
}

.site-filter-box {
  width: 300px;
}

.map-type {
  position: absolute !important;
  left: 804px;
  top: 20px;
  display: flex;
  z-index: 10000;
  flex-direction: row;
}

.gis-map-type {
  position: absolute !important;
  left: 1178px;
  top: 20px;
  display: flex;
  z-index: 100;
  flex-direction: row;
}

@media screen and (max-width: 1366px) {
  .gis-map-type {
    left: 1168px;
  }
}

@media screen and (max-width: 1024px) {
  .gis-map-type {
    left: 1158px;
  }
}

.map-legend {
  position: absolute !important;
  right: 10px;
  bottom: 5px;
  display: flex;
  background: #fff !important;
  padding: 10px;
}

.map-type-li {
  display: flex;
  padding-left: 9px;
}

.map-legend ul {
  list-style: none;
  padding: 0;
}

.map-legend ul li {
  padding-bottom: 5px;
}

.map-legend ul li img {
  width: 20px;
}

.has-search {
  padding: 5px 10px;
}

.has-search .form-control {
  padding-left: 2.375rem;
  padding-right: 2.375rem;
}

.form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

/* .esri-basemap-toggle__image {
  height: 50px !important;
  width: 50px !important;
} */
.esri-basemap-toggle__image--secondary {
  margin: 0 0;
}

.esri-basemap-toggle__image--secondary {
  box-shadow: none !important;
}

.esri-basemap-toggle__container {
  box-shadow: none !important;
}

.map-type-box {
  width: 150px;
}

.gis-button {
  display: flex !important;
  margin-left: 5px;
  border: none;
  align-items: center;
  padding: 3px 10px !important;
}

.info-icon {
  font-size: 24px;
  color: yellow;
  cursor: pointer;
}

.notification-link {
  cursor: pointer;
  color: blue;
}

.esri-ui-top-right {
  top: 80px !important;
}

.disabled {
  background: lightgray !important;
}

.action-button {
  width: 470px;
}

.sticky-heading {
  position: sticky;
  top: 0;
}

.lat-lon {
  display: flex;
}

.file-upload-type {
  display: flex;
  flex-direction: column;
}

.file-upload-type-1 {
  display: flex;
}

.trans-slider {
  position: absolute;
  top: 15px;
  left: 908px;
  width: 160px;
  z-index: 1000;
}

#opacitySlider {
  width: 100%;
  height: 20px;
  position: relative;
  cursor: pointer;
}

#sliderHandle {
  width: 10px;
  height: 30px;
  position: absolute;
  top: -5px;
}

.base-map-button {
  position: absolute;
  top: 90px;
  left: 1230px;
  z-index: 1000;
}

.label-button {
  position: absolute;
  top: 15px;
  left: 1072px;
  border: none;
  /* width: 160px; */
  z-index: 1000;
  padding: 3px 10px !important;
}

.basemap-button {
  position: absolute;
  top: 15px;
  left: 1158px;
  border: none;
  /* width: 160px; */
  z-index: 1000;
  padding: 3px 10px !important;
}

.hide-base-map {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.esri-component.esri-legend.esri-widget.esri-widget--panel {
  background: #fff !important;
}

.esri-legend__layer-caption {
  display: none !important;
}

.z-index-sticky {
  z-index: 1020;
}

.gis-icon {
  display: none;
}

.gis-label {
  display: block;
}

.read-notification-text {
  display: flex;
  justify-content: space-between;
}

#legend-div {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
}
/* .esri-legend div {
  display: flex;
  flex-direction: column-reverse;
} */

@media (max-width: 1490px) {
  .action-label {
    display: inline;
  }

  .action-button {
    width: 371px;
  }
  .box-padding {
    padding: 2px !important;
  }

  .gis-icon {
    display: none;
  }

  .gis-label {
    display: block;
  }

  .trans-slider {
    width: auto;
  }

  .basemap-button {
    left: 1037px;
  }
}
@media (max-width: 1242px) {
  .gis-icon {
    display: block;
  }

  .gis-label {
    display: none;
  }
  .label-button {
    left: 966px;
  }
  .gis-map-type {
    left: 1050px;
  }
}

.user-search-box {
  margin-right: 300px;
  align-items: center;
  display: flex;
}

.cancle-icon {
  margin-left: -24px;
  color: #bdbdbd;
  cursor: pointer;
}

.cancle-icon-proposal {
  margin-left: -24px;
  color: #bdbdbd;
  font-size: 16px;
  cursor: pointer;
}

.proposal-search-icon {
  font-size: 16px;
}

.action-btn button {
  background: #fff;
  color: blue;
  border: navajowhite;
  font-weight: bold;
}

.popover {
  padding: 10px;
}

.expand-list {
  list-style: none;
  background: #ffffffde;
  color: #343030;
  height: 150px;
  overflow-y: scroll;
  padding: 10px 20px;
  opacity: 0.8;
  border-radius: 5%;
  font-weight: 400;
}

.expand-list li {
  display: flex;
  justify-content: space-between;
}

.a-link-with-underline {
  cursor: pointer;
  text-decoration: underline;
}

.land-details-field {
  display: flex;
}

.popover-container {
  max-height: 250px;
  overflow-y: scroll;
}

.m-label {
  color: #000;
}

.file-list-view {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.file-list-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  align-items: end;
}

.file-list-item .view-file {
  color: #525252;
}

.file-list-item .view-file:hover {
  color: #0000ff;
}

.header-body {
  /* height: calc(100% - 95px); */
  display: flex;
  flex-flow: column;
  /* gap: 20px; */
}

.dashboard-map-div {
  height: calc(100% - 45px);
}

.dashboard-block {
  display: flex;
  flex-flow: column;
  gap: 10px;
  height: calc(100vh - 66px);
}

.colcustom-3 {
  flex: 0 0 auto;
  width: 50%;
}

.colcustom-4 {
  flex: 0 0 auto;
  width: 50%;
}

.blue-color {
  color: blue;
  font-weight: bold;
}

.gis-map-title {
  background: #fff;
  margin: 0px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 2px;
}

.col-box {
  /* height: 100%; */
}

.mis-list-header {
  display: flex !important;
  align-items: baseline;
}

.react-datepicker-wrapper {
  width: 100%;
}

.widget-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget-content p {
  font-size: 20px;
  font-weight: 600;
  color: #000 !important;
}

.dashboard-content-border {
  border: solid 2px #000 !important;
  border-radius: 0px;
  color: #000;
  font-weight: 500;
  background-color: #fff;
}

.dashboard-content-border::after {
  content: none !important;
}

h5.card-title {
  font-size: 15px;
  color: #000 !important;
}

.action-btn button {
  color: #0070c0;
  font-size: 20px;
}

.date-picker-field {
  border: solid 2px #000;
  /* height: 47px; */
  border-radius: 0px;
}

.date-picker-field input {
  /*  height: 43px; */
  border-radius: 0px;
  color: #000;
  text-align: center;
}

.date-picker-field input::placeholder {
  color: #000;
  font-weight: 500;
  text-align: center;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  content: "\276F";
  position: absolute;
  top: 2%;
  left: 45%;
  margin: auto;
  rotate: -90deg;
  color: #a6a6a6;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:last-child::before {
  content: "\276F";
  position: absolute;
  top: 90%;
  left: 46%;
  margin: auto;
  rotate: 90deg;
  color: #a6a6a6;
}

.dropdown-menu-content {
  max-height: 300px;
  height: 200px;
  overflow-y: scroll;
  padding-top: 0px;
}

.dropdown-menu-content li:first-child {
  position: sticky;
  top: 0px;
  margin: 0px 10px;
}

.dropdown-menu-content .dropdown-item span {
  margin-left: 5px;
}

.filter-row {
  display: flex;
  justify-content: space-between;
}

.filter-item {
  width: 16%;
}

.filter-item .btn-group {
  width: 100%;
}

.filter-item:last-child .form-group {
  width: 100%;
}

.chart-box {
  width: 32%;
  padding: 0;
  background: #fff;
  border: solid 2px #000;
  height: 100%;
}

.chart-box:first-child {
  margin-left: 10px;
}

.chart-box:last-child {
  margin-right: 10px;
}

.donut-bg-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 160px;

  margin-top: -5px;
  border-radius: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.donut-bg-1 p {
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
  color: #000;
  margin: 0;
}

.donut-bg-1 p:nth-child(1) {
  font-size: 1.7vw;
  background: -webkit-linear-gradient(
    rgb(171, 46, 110) 17%,
    rgb(0, 112, 180) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donut-bg-1 p:nth-child(4) {
  font-size: 1.3vw;
  background: -webkit-linear-gradient(
    rgb(171, 46, 110) 17%,
    rgb(0, 112, 180) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donut-bg-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 220px;
  height: 155px;

  background-size: cover;
  border-radius: 30%;
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.donut-bg-2 p {
  margin: 0;
  text-align: center;
  font-weight: 500;
  color: #000;
  font-size: 1vw;
}

.donut-bg-2 p:nth-child(1) {
  font-size: 1.7vw;
  background: -webkit-linear-gradient(
    rgb(171, 46, 110) 17%,
    rgb(0, 112, 180) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donut-bg-2 p:nth-child(4) {
  font-size: 1.3vw;
  background: -webkit-linear-gradient(
    rgb(171, 46, 110) 17%,
    rgb(0, 112, 180) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chart-wrapper {
  position: relative;
  display: inline-block;
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
}

.chart-heading {
  padding-top: 10px;
  padding-left: 20px;
  background: #fff;
  color: #163e64;
  font-weight: 500;
  font-size: 20px;
}

.chart-heading span {
  font-size: 16px;
}

.chart-legend {
  text-align: right;
  margin-right: 10px;
  font-size: 15px;
}

.dropdown-toggle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.no-data {
  transform: translate(-50%, 50%);
}

.site-th {
  background: #47a0e7d9;
  padding: 8px 16px;
}

.export-btn {
  position: absolute;
  top: 8px;
  left: 180px;
}

.export-btn-site {
  position: absolute;
  top: 15px;
  left: 220px;
}

@media (max-width: 1800px) {
  .donut-bg-1 {
    width: 140px;
    /* height: 100px; */
  }

  .donut-bg-2 {
    width: 140px;
    /* height: 100px; */
  }

  .chart-wrapper {
    width: 80%;
    margin-left: 10%;
  }

  .chart-heading {
    font-size: 18px;
  }

  .chart-heading span {
    font-size: 15px;
  }

  .date-picker-field {
    height: 41px;
  }

  .date-picker-field input {
    height: 37px;
  }

  .chart-legend {
    text-align: right;
    margin-right: 10px;
    font-size: 13px;
    padding-top: 0 !important;
  }
}

@media (max-width: 1180px) {
  .chart-wrapper {
    width: 100%;
  }
}

@media (min-width: 1180px) {
  .colcustom-3 {
    flex: 0 0 auto;
    width: 33%;
  }

  .colcustom-4 {
    flex: 0 0 auto;
    width: 25%;
  }

  .widget-desc {
    font-size: 20px;
    color: #163e64;
    font-weight: bold;
  }

  .filter-form {
    padding-top: 10px;
  }

  .col-box {
    /* min-height: 74px; */
  }
}

@media (min-width: 1920px) {
  .dashboard-card h5 {
    font-size: 0.677vw;
    line-height: 1.016vw;
  }

  .dashboard-card button {
    font-size: 0.833vw;
    line-height: 1.25vw;
  }

  .dashboard-card .icon {
    padding: 0.625vw;
  }

  .dashboard-card .icon i {
    font-size: 1.042vw;
  }

  /* .donut-bg {
    width: 240px;
    height: 240px;
  } */
}
