@font-face {
  font-family: Adani-Regular;
  src: /* url(../fonts/Adani-ExtraLight.woff) format('woff'), */ url(../fonts/Adani-ExtraLight.ttf)
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Adani-Regular;
  src: url(../fonts/Adani-Medium.woff) format("woff"),
    url(../fonts/Adani-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Adani-Regular;
  src: url(../fonts/Adani-Regular.woff) format("woff"),
    url(../fonts/Adani-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Adani-Regular;
  src: url(../fonts/Adani-Regular_1.woff) format("woff"),
    url(../fonts/Adani-Regular_1.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Adani-Regular;
  src: /* url(../fonts/Adani-Light.woff) format('woff'), */ url(../fonts/Adani-Light.ttf)
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Adani-Regular;
  src: url(../fonts/Adani-Bold.woff) format("woff"),
    url(../fonts/Adani-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Adani-Regular;
  src: url(../fonts/Adani-SemiBold.woff) format("woff"),
    url(../fonts/Adani-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

body,
html {
  font-family: Adani-Regular;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e91e63;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.bg-gray-200 {
  background-color: #f0f2f5 !important;
}

.tab-btn {
  background: #22f52bc7 !important;
  border: navajowhite;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.reject {
  background: #f10909d9 !important;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.panel-sub-title {
  /* font-size: clamp(1.3rem, 1.5vw + 0.5rem, 1.2rem); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background-image: linear-gradient(305deg, tomato, gold, cyan);
}

.panel-sub-title > *,
.panel-sub-title::after {
  grid-area: text;
}

.panel-sub-title::after {
  content: attr(data-text);
  color: var(--paper);
  transform: translate(0.1em, 0.1em);
  filter: drop-shadow(0.015em 0.015em 0.025em var(--shadow));
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(var(--highlight), var(--paper));
}

.map {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
}

.dropdown-toggle::after {
  margin-top: 10px;
  font-size: 24px;
}

/*Legend specific*/
.legend {
  padding: 6px 8px;
  font: 14px;
  /* font: 14px Arial, Helvetica, sans-serif; */
  background: white;
  background: rgba(255, 255, 255, 0.8);
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
  /*border-radius: 5px;*/
  line-height: 24px;
  color: #555;
}

.legend h4 {
  text-align: center;
  font-size: 16px;
  margin: 2px 12px 8px;
  color: #777;
}

.legend span {
  position: relative;
  bottom: 3px;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin: 0 8px 0 0;
  opacity: 0.7;
}

.legend i.icon {
  background-size: 18px;
  background-color: rgba(255, 255, 255, 1);
}

.active {
  background-image: linear-gradient(160deg, #7d8dd9 14%, #ef1463 100%);
  border-radius: 6%;
}

.nav-link:hover {
  background-image: linear-gradient(160deg, #7d8dd9 14%, #ef1463 100%);
  border-radius: 6%;
}

.panel-header {
  color: white;
  text-align: center;
  font-size: 18px;
  background: #5fbb5f;
  padding: 5px;
  margin: 0 25px;
  border-radius: 11% 38% 1% 51%;
}

.panel-sub-header {
  color: #ede3e0;
  padding: 5px;
}

.box {
  /* position: absolute; */
  /* float: left; */
  display: flex;
  /* z-index: 1000;
    overflow: hidden; */
}

a.corner-btn {
  background-image: linear-gradient(160deg, #7d8dd9 14%, #ef1463 100%);
}
.a-link .corner-btn {
  background-image: linear-gradient(160deg, #7d8dd9 14%, #ef1463 100%);
}

.box-inner {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: relative;
  z-index: 909;
  transition: width 600ms ease-out, height 600ms ease-out;
  bottom: 230px;
  right: 409px;
}

.box-inner a {
  padding: 3px 14px;
}

.panel-title {
  background-image: linear-gradient(160deg, #7d8dd9 14%, #ef1463 100%);
  /* border-radius: 0 25px 0 0; */
  margin-bottom: 15px;
  background: #e4316f;
  display: flex;
  text-align: center;
  justify-content: space-between;
}

/* .request-list-corner-btn {
    position: absolute;
    z-index: 1000;
    transition: top 600ms ease-out, bottom 600ms ease-out;
    bottom: 256px;
    left: 875px;
} */

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50px;
  height: 40px;
  font-size: 0;
}

.close-btn:hover {
  cursor: pointer;
  color: #c4bebee0;
}

.close-btn:before,
.close-btn:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 20px;
  background-color: #f0f0f0;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: top left;
  content: "";
}

.close-btn:after {
  transform: rotate(-45deg) translate(-50%, -50%);
}

.proposal-detail-init {
  border-radius: 25px 0 0 25px;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  width: 350px;
  height: 90vh;
  right: 1px;
  transition: width 600ms ease-out, height 600ms ease-out;
}

.proposal-detail-init--collapsed {
  width: 0;
}

.proposal-detail-init .table {
  color: white;
}

.proposal-detail-corner-btn {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  z-index: 909;
  transition: left 600ms ease-out, right 600ms ease-out;
  top: 330px;
  right: 291px;
}

.proposal-detail-corner-btn--collapsed {
  right: -59px;
}

.proposal-list-init {
  position: absolute;
  bottom: 1px;
  /* height: 80vh; */
  right: 355px;
  transform: unset !important;
  opacity: 0.8;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  transition: width 600ms ease-out, height 600ms ease-out;
  border-radius: 25px 0 25px;
}

.proposal-list-init--collapsed {
  height: 0 !important;
}

.proposal-list-corner-btn {
  position: absolute;
  z-index: 1000;
  transition: top 600ms ease-out, bottom 600ms ease-out;
  bottom: 256px;
  right: 355px;
}

.proposal-list-corner-btn--collapsed {
  bottom: 0 !important;
}

.request-detail-init {
  border-radius: 0 25px 25px 0;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  width: 330px;
  /* height: 90vh; */
  transition: width 600ms ease-out, height 600ms ease-out;
}

.request-detail-init--collapsed {
  width: 0;
}

.request-detail-init .table {
  color: white;
}

.request-list-init {
  position: absolute;
  bottom: 1px;
  /* height: 80vh; */
  
  transform: unset !important;
  opacity: 0.8;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  transition: width 600ms ease-out, height 600ms ease-out;
  border-radius: 25px 0 25px;
}
.land-req-list-position{
  left: 335px !important; 
}

.request-list-init--collapsed {
  height: 0 !important;
}

.request-list-corner-btn--collapsed {
  bottom: 0 !important;
}

.request-list-corner-btn {
  position: absolute;
  z-index: 1000;
  transition: top 600ms ease-out, bottom 600ms ease-out;
  bottom: 256px;
  /* left: 875px; */
}

.new-request-init {
  border-radius: 25px 0 0 25px;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  width: 400px;
  /* height: 88%; */
  right: 1px;
  transition: width 600ms ease-out, height 600ms ease-out;
}

.new-request-init--collapsed {
  width: 0;
}

.new-request-init .table {
  color: white;
}

.new-request-corner-btn {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  z-index: 909;
  transition: left 600ms ease-out, right 600ms ease-out;
  top: 160px;
  right: 349px;
}

.new-request-corner-btn--collapsed {
  right: -53px;
}
/* .leaflet-pane{
    position: inherit !important;
} */

.eng-detail-init {
  border-radius: 0 25px 25px 0;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  left: 0px;
  bottom: 5px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  width: 400px;
  height: 90vh;
  transition: width 600ms ease-out, height 600ms ease-out;
}

.eng-detail-init--collapsed {
  width: 0;
}

.eng-detail-init .table {
  color: white;
}

.eng-box-inner {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  z-index: 909;
  transition: left 600ms ease-out, right 600ms ease-out;
  bottom: 310px;
  left: 338px;
}

.eng-box-inner--collapsed {
  left: -61px;
}

.eng-box-inner a {
  padding: 3px 14px;
}

.new-proposal-init {
  border-radius: 25px 0 0 25px;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  width: 400px;
  height: 88%;
  right: 1px;
  transition: width 600ms ease-out, height 600ms ease-out;
}

.new-proposal-init--collapsed {
  width: 0;
}

.new-proposal-init .table {
  color: white;
}

.new-proposal-corner-btn {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  z-index: 909;
  transition: left 600ms ease-out, right 600ms ease-out;
  bottom: 180px;
  right: 359px;
}

.new-proposal-corner-btn--collapsed {
  right: -41px;
}

.analysis-detail-init {
  border-radius: 0 25px 25px 0;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  width: 400px;
  height: 90vh;
  transition: width 600ms ease-out, height 600ms ease-out;
  left: 1px;
}

.analysis-detail-init--collapsed {
  width: 0;
}

.analysis-detail-init .table {
  color: white;
}

.analysis-box-inner {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  z-index: 909;
  transition: left 600ms ease-out, right 600ms ease-out;
  bottom: 16vh;
  left: 319px;
}

.analysis-box-inner--collapsed {
  left: -80px;
}

.analysis-box-inner a {
  padding: 3px 14px;
}

body {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  color: #525f7f;
  background-color: #f8f9fe;
}

h2,
h5,
.h2,
.h5 {
  /* font-family: inherit; */
  /* font-weight: 600; */
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: #32325d;
}

h2,
.h2 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: border-box;
}

.bg-info {
  background-color: #11cdef !important;
}

.bg-yellow {
  background-color: #ffd600 !important;
}

a.bg-yellow:hover,
a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #ccab00 !important;
}

.border-radius-lg {
  border-radius: 0.5rem;
}

.bg-gradient-success {
  background-image: linear-gradient(195deg, #66bb6a 0%, #43a047 100%);
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
}

.bg-gradient-chart-1 {
  background-image: linear-gradient(195deg, #ec407a 0%, #d81b60 100%);
}

.bg-gradient-dark {
  background-image: linear-gradient(195deg, #ab2e6e 0%, #0070b4 100%);
  /* background-image: linear-gradient(195deg, #42424a 0%, #191919 100%); */
}

.border-radius-lg {
  border-radius: 0.5rem;
}

.shadow-primary {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(233, 30, 99, 0.4) !important;
}

.card-stats {
  border: solid 3px #000;
}

.card-stats .card-body {
  padding: 8px 16px;
}

.main-content {
  position: relative;
}

.footer {
  padding: 2.5rem 0;
  background: #f7fafc;
}

.footer .copyright {
  font-size: 0.875rem;
}

.header {
  position: relative;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 2.25rem;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.icon-shape i {
  font-size: 1.25rem;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #e55d87 0%,
    #5fc3e4 51%,
    #e55d87 100%
  );
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.user-mgt-list-corner-btn {
  position: absolute;
  z-index: 1000;
  transition: top 600ms ease-out, bottom 600ms ease-out;
  bottom: 256px;
  left: 30px;
}

.user-mgt-list-corner-btn--collapsed {
  bottom: 0 !important;
}

.user-mgt-init {
  position: absolute;
  bottom: 1px;
  /* height: 80vh; */
  left: 5px;
  transform: unset !important;
  opacity: 0.8;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  transition: width 600ms ease-out, height 600ms ease-out;
  border-radius: 25px 0 25px;
}

.dx-table {
  max-height: 75vh;
}

.dx-table-mis {
  max-height: 62vh;
}

.user-mgt-init--collapsed {
  height: 0 !important;
}

.user-mgt-detail-init {
  border-radius: 25px 0 0 25px;
  position: absolute;
  opacity: 0.8;
  top: 70px;
  bottom: 5px;
  z-index: 1000;
  background-image: linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%);
  overflow: hidden;
  width: 350px;
  /* height: 550px; */
  height: 90vh;
  right: 1px;
  transition: width 600ms ease-out, height 600ms ease-out;
}

.user-mgt-detail-init--collapsed {
  width: 0;
}

.user-mgt-detail-init .table {
  color: white;
}

.user-mgt-detail-corner-btn {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  z-index: 909;
  transition: left 600ms ease-out, right 600ms ease-out;
  top: 168px;
  right: 294px;
}

.user-mgt-detail-corner-btn--collapsed {
  right: -58px;
}

.user-mgt-dh-detail-corner-btn {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  z-index: 909;
  transition: left 600ms ease-out, right 600ms ease-out;
  top: 168px;
  right: 294px;
}

.user-mgt-dh-detail-corner-btn--collapsed {
  right: -47px;
}

/* .user-mgt-detail-init tr:hover {
    cursor: pointer;
    background: #0000ff2e;
}

.user-mgt-detail-init tr:nth-child(even) {
    background-color: #e32e8bab
} */

.dashboard-loader-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0070b4;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  z-index: 111;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1400px) {
  .card-stats .card-body {
    padding: 2px 16px;
  }
}
.fix-column th:first-child,
.fix-column td:first-child {
  position: sticky;
  left: 0px;
  background-color: rgba(71, 160, 231, 0.85);
  font-weight: bold;
}
